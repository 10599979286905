import { acceptHMRUpdate, defineStore } from "pinia";
import { loadGTM } from "~/cookie-bar";
import cashtecLogoClassic from "cashtec-common/src/assets/svg/cashtec-logo.svg?url";
import cashtecLogoAlternative from "~/assets/svg/cashtec-logo-alt.svg?url";

export const usePartnerStore = defineStore("partnerStore", () => {
  const authStore = useAuthStore();
  const { $api } = useNuxtApp() as { $api: IApiInstance };
  const route = useRoute();

  const partner = ref<PartnerDetail | null>(null);
  const gtmLoaded = ref<boolean>(false);

  const dataLayerName = computed<string>(() => partner.value?.seo_name ? `dl_${partner.value?.seo_name.replaceAll("-", "_")}` : "");

  function gtmLoad () {
    if (!gtmLoaded.value && partner?.value?.gtm_code && partner?.value?.seo_name) {
      if (import.meta.client && import.meta.env.PROD) {
        loadGTM(partner.value!.gtm_code, dataLayerName.value);
      }
      gtmLoaded.value = true;
    }
  }

  async function getDetailData (seoName: string | null = null) {
    const partnerSeoName = seoName || route.params.partner as string;
    const { data } = await $api.user.partnerDetail(partnerSeoName);
    if (data.value) {
      partner.value = data.value;
      gtmLoad();
    }
  }

  const getBuyoutPartner = computed<PartnerDetail | null>(() => {
    if (authStore.isLoggedIn && authStore.data?.partner?.seo_name && partner.value !== null && route.params.partner) {
      return partner.value;
    } else if (authStore.isLoggedIn && authStore.data?.partner?.seo_name && partner.value !== null && !route.params.partner) {
      return authStore.data!.partner;
    }
    if (partner.value !== null) {
      return partner.value;
    } else if (authStore.isLoggedIn && authStore.data?.partner?.seo_name) {
      return authStore.data!.partner;
    }

    return null;
  });

  const cashtecLogo = computed<string>(() => {
    if (partner.value?.use_alternative_logo) {
      return cashtecLogoAlternative;
    } else {
      return cashtecLogoClassic;
    }
  });

  return {
    partner,
    dataLayerName,
    gtmLoaded,
    getBuyoutPartner,
    gtmLoad,
    cashtecLogo,
    getDetailData
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePartnerStore, import.meta.hot));
}
